import { List, Popover } from "antd";
import { getDocuments } from "firebase.utils";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { TypeStore } from "store/reducers";

export function LinkHeader() {
    const {system} = useSelector((state: TypeStore) => state)
    const navigate = useNavigate()

    const listLinks: any = system.menu.filter((item) => item.open).map((m) => {
        return {
            key: `/${m.path}`,
            title: m.title?.toLocaleUpperCase(),
        }
    })

    const [categories, setCategories] = React.useState(listLinks ?? [])

    useEffect(() => {
        const fetchCategories = async () => {
            const response = await getDocuments('categories')
            const filter = response.filter((item: any) => item.showMenu)
            
            const data = filter.map((item: any) => {
                return {
                    key: `/danh-muc/${item.categoriesName}`,
                    title: item.categoriesName,
                    subcategories: item.subcategories,
                }
            })


            setCategories(data)
        }

        fetchCategories()
    }, []);

    console.log(categories);
    
    return <>
    {[
        ...listLinks,
        ...categories
    ].map((element) => {
        console.log(element);
        
        if(element?.subcategories?.length > 0) {
            return <Popover
                placement="bottom"
                showArrow={true}
               zIndex={1000}
                content={
                    <List
                    style={{width: 200, padding: 0, background: '#192139', borderRadius: 10}}
                        dataSource={element?.subcategories}
                        renderItem={(item: any) => (
                            <List.Item style={{padding: 5, paddingLeft: 10}} onClick={() => navigate(`/danh-muc/${element.title}?sub=${item.id}`)}>
                                <span style={{width: '100%', padding: 5}}  className="text-12 text-hover color-w">{item.name}</span>
                            </List.Item>
                        )}
                    />
                }
            >
                <Link className="color-w text-hover text-12" to={element.key}>
                {element.title.toUpperCase()}
            </Link> 
        </Popover>

        }

        return  <Link className="color-w text-hover text-12" to={element.key} key={element.key}>
            {element.title}
        </Link>
    }
    )}
        {/* <span className="color-w text-hover text-12">GIỚI THIỆU</span>
        <span className="color-w text-hover text-12">VÒNG TAY TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">NHANG TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">TRẦM HƯƠNG ĐỐT</span>
        <span className="color-w text-hover text-12">MỸ NGHỆ TRẦM HƯƠNG</span>
        <span className="color-w text-hover text-12">BLOG</span>
        <span className="color-w text-hover text-12">LIÊN HỆ</span> */}
    </>
}