import { Flex, Rate, Typography } from 'antd'
import React from 'react'


export default function FooterItem({item}: {item: any}) {
  console.log('====================================');
  console.log('item', item);
  console.log('====================================');
    const format = (num: number): string => {
        if (num < 1000) return num.toString();
        if (num < 1000000) return (num / 1000).toFixed(1) + 'K';
        if (num < 1000000000) return (num / 1000000).toFixed(1) + 'M';
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  return (
    <Flex justify='space-between' align='center' className='footer-item'>
         <Typography.Text style={{fontSize: 12}}>
                                       Đã bán {format(item.purchases ?? 0)} 
                                    </Typography.Text>
                                    
        <div className="flex-row flex-center gap-8 flex-wrap">
                                        <Rate disabled value={item.rate} className={`text-15`} />
                                        <span className="text-12 f-500 text-blur">{item.reviews} đánh giá</span>
                                    </div>

                                   
    </Flex>
  )
}
