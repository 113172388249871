import { Button, Empty, Rate, Select, Skeleton } from 'antd';
import { firestore } from 'firebase.config';
import { DocumentData, QueryDocumentSnapshot, collection, getDocs, limit, query, startAfter, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { IoReturnUpBack } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { TypeStore } from 'store/reducers';
import { PiSortDescendingBold, PiSortAscendingBold } from 'react-icons/pi'
import LoadingSpinner from 'components/lds-spinner/lds-spinner';
import FooterItem from 'components/FooterItem';

interface TypePriceSearch {
    from: number,
    to: number
}

export function List() {
    const { categories } = useParams()
    const [items, setItems] = useState<any[]>([]);
    const [itemsAfterFilter, setItemsAfterFilter] = useState<any[]>([]);
    const [lastDoc, setLastDoc] = useState<QueryDocumentSnapshot<DocumentData, DocumentData> | null>(null);
    const { searchValue, themelayout } = useSelector((state: TypeStore) => state)
    const [smallerPrice, setSmallerPrice] = useState<null | TypePriceSearch>(null)
    const [filterByStar, setFilterByStar] = useState<null | number>(null)
    const [selectTypePrice, setSelectTypePrice] = useState<null | string>(null)
    const [infDM, setInfDM] = useState<any>(null)
    const itemsPerPage = 24;
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc' | null>(null);
    const history = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [sub, setSub] = useState('')
    const location = useLocation();

    const listTypePrice = [
        {
            value: {
                from: 0,
                to: 1000000
            },
            label: 'Dưới 1.000.000 VNĐ',
        },
        {
            value: {
                from: 1000000,
                to: 5000000
            },
            label: 'Từ 1.000.000 VNĐ đến 5.000.000 VNĐ',
        },
        {
            value: {
                from: 5000000,
                to: 1000000000
            },
            label: 'Trên 5.000.000 VNĐ',
        },
    ]

    useEffect(() => {
        const data = listTypePrice.find((element) => element.label === selectTypePrice)?.value
        if (data) {
            setSmallerPrice(data)
        } else {
            setSmallerPrice(null)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectTypePrice])


    const getDanhMuc = async () => {
        try {
            const q = query(collection(firestore, 'categories'), where("categoriesName", "==", categories));

            const items = (await getDocs(q)).docs

            const data = items.map((doc) => {
                return { id: doc.id, ...doc.data() } as any
            })

            setInfDM(data[0])
        } catch (error) {

        }
    }

    console.log('items', items);
    

    const loadMore = async () => {
        try {
            setIsLoading(true)
            let q = query(collection(firestore, 'items'), where("type", "==", categories));

            if (lastDoc) {
                q = query(q, startAfter(lastDoc));
            }

            q = query(q, limit(itemsPerPage));

            await getDocs(q)
                .then((querySnapshot) => {
                    const newItems: any[] = [];
                    querySnapshot.forEach((doc) => {
                        const data = doc.data();
                        
                        newItems.push({ id: doc.id, ...data } as any);
                    });

                    
                    if (newItems.length > 0) {
                        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                        let filter =[...items, ...newItems];
                       
                        setItems(filter);
                        setItemsAfterFilter(filter)
                    }
                })
                .catch((error) => {
                    console.error('Error getting documents: ', error);
                });
        } catch (error) {

        } finally {
            setIsLoading(false)
        }
    };

    useEffect(() => {
        getDanhMuc()
    }, [])

    useEffect(() => {
        loadMore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, sub, categories]);

    useEffect(() => {
        // set url query sub
        const searchParams = new URLSearchParams(window.location.search);
        const sub = searchParams.get('sub');
        setSub(sub || '')
    }, [location])

    useEffect(() => {
        const filteredItems = items.filter((item) => {
            let passPriceFilter = true;
            let passRateFilter = true;

            if (smallerPrice) {
                passPriceFilter =
                    (item.priceSale >= smallerPrice.from && item.priceSale <= smallerPrice.to) || smallerPrice == null;
            }

            if (filterByStar !== null) {
                passRateFilter = item.rate === filterByStar || filterByStar == null;
            }
            
            if(sub && sub != item.subCategory) {
                return false
            }


            return passPriceFilter && passRateFilter;
        });

        setItemsAfterFilter(filteredItems);
    }, [smallerPrice, filterByStar, items, sub]);
    
    useEffect(() => {
        const targetElement = document.getElementById('scroll-dm');
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, []);

    const handleSort = (order: 'asc' | 'desc') => {
        setSortOrder(order);
        const sortedItems = [...itemsAfterFilter];
        sortedItems.sort((a, b) => {
            if (order === 'asc') {
                return a.priceSale - b.priceSale;
            } else {
                return b.priceSale - a.priceSale;
            }
        });
        setItemsAfterFilter(sortedItems);
    };

    return (
        <div className='main'>
            <div className='mg-header component-container flex-col gap-12' id='scroll-dm'>
                <Button type="dashed" className="mgl-auto mgt-24 flex-row gap-4 flex-center" onClick={() => history(-1)}>
                    <IoReturnUpBack className="text-15" /> <span>Quay lại</span>
                </Button>
                {isLoading
                    ? <LoadingSpinner />
                    : <>
                        <div className='list-fill'>
                            <div className='flex-col gap-12 pd-12' style={{
                                backgroundColor: themelayout === 'dark' ? '#080b14' : '#f7f7f7'
                            }}>
                                <h1 className='text-16 f-400'>Bộ lọc</h1>
                                <div className='flex-row gap-12 flex-wrap mgl-auto'>
                                    <Select
                                        dropdownStyle={{
                                            backgroundColor: themelayout === 'dark' ? '#848b9c' : 'white',
                                        }}
                                        className={`class-${themelayout}`}
                                        style={{
                                            width: '300px',
                                        }}
                                        placeholder="Lọc theo giá"
                                        onChange={(value) => setSelectTypePrice(value)}
                                        allowClear
                                        options={[
                                            {
                                                value: 'Dưới 1.000.000 VNĐ',
                                                label: 'Dưới 1.000.000 VNĐ',
                                            },
                                            {
                                                value: 'Từ 1.000.000 VNĐ đến 5.000.000 VNĐ',
                                                label: 'Từ 1.000.000 VNĐ đến 5.000.000 VNĐ',
                                            },
                                            {
                                                value: 'Trên 5.000.000 VNĐ',
                                                label: 'Trên 5.000.000 VNĐ',
                                            },
                                        ] as any}
                                    />
                                    <Select
                                        dropdownStyle={{
                                            backgroundColor: themelayout === 'dark' ? '#848b9c' : 'white',
                                        }}
                                        className={`class-${themelayout}`}
                                        placeholder="Số sao"
                                        onChange={(value) => setFilterByStar(value)}
                                        allowClear
                                        options={Array.of(5, 4, 3, 2, 1).map((element) => {
                                            return {
                                                value: element,
                                                label: `${element} sao`
                                            }
                                        })}
                                    />
                                    <button
                                        className={`class-${themelayout} sort-button`}
                                        style={{
                                            backgroundColor: themelayout === 'dark' ? '#272d3d' : 'white',
                                        }}
                                        onClick={() => {
                                            if (sortOrder === 'asc') {
                                                handleSort('desc');
                                            } else {
                                                handleSort('asc');
                                            }
                                        }}
                                    >
                                        {sortOrder === 'asc' ? (
                                            <PiSortAscendingBold />
                                        ) : sortOrder === 'desc' ? (
                                            <PiSortDescendingBold />
                                        ) : (
                                            'Sắp xếp'
                                        )}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <h3 className='text-13 f-400 mgl-auto'>Hiển thị tất cả {itemsAfterFilter.length} kết quả</h3>
                        {itemsAfterFilter.length < 1
                            ? <Empty />
                            : <div className="grid-template-home gap-24">
                                {itemsAfterFilter.map((element) => (
                                    <div>
                                        <Link className={`item slider ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`} to={`/chi-tiet/${element.id}`} key={element.id}>
                                            <div className={`w-100 h-100 pointer relative mini box-shadow ${themelayout === 'dark' ? 'dark-mode' : 'white-mode'}`}>
                                                {element.typeProduct ? element.typeProduct === 'Sản phẩm mới'
                                                    ? <div className='devvn_label_product'>
                                                    <span className="sale">{
                                                        (element.price && element.priceSale) ? `- ${(100 - ((element.priceSale / element.price) * 100)).toFixed(1)} %` : 'Giảm sâu'
                                                    }</span>
                                                </div>
                                                    : <div className='devvn_label_product'>
                                                        <span className="sale">Bán chạy</span>
                                                    </div>
                                                    : null
                                                }

                                                <div className="item-frame-image">
                                                    {element?.listFiles && <>
                                                        <img className="img-item bdr-t-4" src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[0]} alt="" />
                                                        {element.listFiles.filter((img: string) => !img.includes('.mp4')).length > 1 && <img className='img-item bdr-t-4 overlay-image' src={element.listFiles.filter((img: string) => !img.includes('.mp4'))[1]} alt="" />}
                                                    </>}
                                                </div>

                                                <div className="inf-item flex-col gap-8">
                                                    <p className="text-13 f-400 text-center">{element.name}</p>
                                                    <div className="flex-row flex-center gap-8 flex-wrap">
                                                        <span className="text-13 f-500">{Number(element.priceSale).toLocaleString()} VNĐ</span>
                                                        {element.price && <div className="flex-row flex-center relative">
                                                            <span className="text-12 f-500 text-blur">{Number(element.price).toLocaleString()} VNĐ</span>
                                                            <div className="line-center"></div>
                                                        </div>}
                                                    </div>
                                                    <FooterItem item={element}/>

                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                ))}
                            </div>
                        }
                        <section className="flex-col gap-12">
                            <div className="header-inf">
                                <h1 className="text-15 f-600">MÔ TẢ DANH MỤC</h1>
                            </div>
                            <div className="box-inf flex-col gap-32">
                                {infDM?.dscCategories && infDM.dscCategories.map((element: any) => {
                                    const paragraphs = element.sub.split('\n').map((paragraph: string, index: number) => (
                                        <p className="text-13 f-400" key={index}>{paragraph}</p>
                                    ));
                                    return (
                                        <div className="flex-col gap-8">
                                            <h1 className="text-15 f-600">{element.title}</h1>
                                            <div className="flex-col gap-4">
                                                {paragraphs}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </section>
                    </>
                }

            </div>
        </div>
    )
}